import 'url-search-params-polyfill'
import React, { useState } from 'react'
import { MenuButton, MenuItem, HelpButton } from '@wf-wfria/ria'
import { IconDownTriangle } from '@wf-wfria/ria-icons'
import { FormattedMessage } from 'react-intl'
import {
	WFINFOLEASE,
	PLINFOLEASE,
	WFORMS,
	SBA,
	LGMTBOB,
	ONBOARD,
	RFI,
	RICOH,
	DLO,
	COBO,
	EASYCONNECT,
	OCRANA,
	OCRAEMEA,
	OCRAANZ
} from '../constants/businessSegmentBrandIds'

export function Header({
	mode,
	auth,
	productName,
	hideNavItems,
	locale,
	showLanguageDropDown,
	intl,
	onChangeLanguageDropDown
}) {
	const [supportAriaExpanded, setSupportAriaExpanded] = useState(false)
	return (
		<header className="Header">
			<div>
				<div className="HeaderLogo">
					<HeaderLogoText mode={mode} intl={intl} />
				</div>
			</div>
			<div className="HeaderNavigationMenu">
				<NonCCHeaderItems
					mode={mode}
					auth={auth}
					hideNavItems={hideNavItems}
					locale={locale}
					showLanguageDropDown={showLanguageDropDown}
					intl={intl}
					onChangeLanguageDropDown={onChangeLanguageDropDown}
					supportAriaExpanded={supportAriaExpanded}
					setSupportAriaExpanded={setSupportAriaExpanded}
				/>
			</div>
			<p className="wf-text6 LendingCenter">{productName}</p>
		</header>
	)
}
export function getRedirectUrl() {
	return encodeURI(document.URL)
}

export function HeaderLogoText({ mode, intl }) {
	switch (mode) {
		case RICOH:
			return (
				<a
					href={getRedirectUrl()}
					title={intl.formatMessage({
						id: 'header.appefmyaccountsricoh.logo-title'
					})}
				>
					<span
						className="header-icon"
						aria-label={intl.formatMessage({
							id: 'header.appefmyaccountsricoh.logo-aria-label'
						})}
					/>
				</a>
			)

		case WFINFOLEASE:
			return (
				<a
					href={getRedirectUrl()}
					title={intl.formatMessage({
						id: 'header.appefmyaccounts.logo-title'
					})}
				>
					<span
						className="header-icon"
						aria-label={intl.formatMessage({
							id: 'header.appefmyaccounts.logo-aria-label'
						})}
					/>
				</a>
			)

		case PLINFOLEASE:
			return (
				<a
					href={getRedirectUrl()}
					title={intl.formatMessage({
						id: 'header.appefmyaccountspl.logo-title'
					})}
				>
					<span
						className="header-icon"
						aria-label={intl.formatMessage({
							id: 'header.appefmyaccountspl.logo-aria-label'
						})}
					/>
				</a>
			)

		case WFORMS:
		case RFI:
		case SBA:
		case LGMTBOB:
		case ONBOARD:
		case DLO:
		case COBO:
		case EASYCONNECT:
			return (
				<a
					href={getRedirectUrl()}
					title={intl.formatMessage({
						id: 'header.appefmyaccountscc.logo-title'
					})}
				>
					<span
						className="header-icon"
						aria-label={intl.formatMessage({
							id: 'header.appefmyaccountscc.logo-aria-label'
						})}
					/>
				</a>
			)
		default:
			return null
	}
}

// todo rename hideNavItems to show
export function NonCCHeaderItems({
	hideNavItems,
	locale,
	mode,
	showLanguageDropDown,
	intl,
	onChangeLanguageDropDown,
	supportAriaExpanded,
	setSupportAriaExpanded
}) {
	if (hideNavItems === true) {
		return null
	}
	if (mode === OCRANA || mode === OCRAEMEA || mode === OCRAANZ) {
		return null
	}
	return (
		<ul className="HeaderMenuList">
			<LanguageDropDown
				locale={locale}
				showLanguageDropDown={showLanguageDropDown}
				mode={mode}
				intl={intl}
				onChangeLanguageDropDown={onChangeLanguageDropDown}
			/>
			<Support
				mode={mode}
				intl={intl}
				showLanguageDropDown={showLanguageDropDown}
				supportAriaExpanded={supportAriaExpanded}
				setSupportAriaExpanded={setSupportAriaExpanded}
			/>
		</ul>
	)
}

export function ContactNumber({ mode, showLanguageDropDown }) {
	if (showLanguageDropDown) {
		return <FormattedMessage id={`support.${mode}.contact-canada-text`} />
	}
	return <FormattedMessage id={`support.${mode}.contact-us-text`} />
}

/* Support Change */
function getHeaderSupportText(mode, showLanguageDropDown) {
	if (mode === WFINFOLEASE) {
		return (
			<div className="HeaderSupportToggle">
				<span>
					<section>
						<div>
							<ContactNumber
								mode={mode}
								showLanguageDropDown={showLanguageDropDown}
							/>
						</div>
						<div>
							<FormattedMessage id="support.appefmyaccounts.monday-friday-text" />
							<FormattedMessage id="support.appefmyaccounts.hours-of-operation-text" />
						</div>
					</section>
				</span>
			</div>
		)
	} else if (mode === PLINFOLEASE) {
		return (
			<div className="HeaderSupportToggle">
				<span>
					<section>
						<div>
							<ContactNumber
								mode={mode}
								showLanguageDropDown={showLanguageDropDown}
							/>
						</div>
						<div>
							<FormattedMessage id="support.appefmyaccountspl.monday-friday-text" />
							<FormattedMessage id="support.appefmyaccountspl.hours-of-operation-text" />
						</div>
					</section>
				</span>
			</div>
		)
	} else {
		// default is Ricoh
		return (
			<div className="HeaderSupportToggle">
				<span>
					<section>
						<div>
							<ContactNumber
								mode={mode}
								showLanguageDropDown={showLanguageDropDown}
							/>
						</div>
						<div>
							<FormattedMessage id="support.appefmyaccountsricoh.monday-friday-text" />
							<FormattedMessage id="support.appefmyaccountsricoh.hours-of-operation-text" />
						</div>
					</section>
				</span>
			</div>
		)
	}
}

export function Support({
	mode,
	intl,
	showLanguageDropDown,
	supportAriaExpanded,
	setSupportAriaExpanded
}) {
	const headerSupport = getHeaderSupportText(mode, showLanguageDropDown)
	const supportLabel = intl.formatMessage({
		id: 'wca-form.button.header-support.label'
	})

	function supportScreenReader() {
		setSupportAriaExpanded(!supportAriaExpanded)
	}

	if (
		mode === RFI ||
		mode === SBA ||
		mode === EASYCONNECT ||
		mode === ONBOARD ||
		mode === WFORMS ||
		mode === LGMTBOB ||
		mode === DLO ||
		mode === COBO
	) {
		return null
	}
	return (
		<li className="HeaderSupport" onClick={supportScreenReader}>
			<HelpButton
				kind="chromeless"
				aria-label={supportLabel}
				aria-expanded={supportAriaExpanded}
				helpBody={headerSupport}
				rightIcon={
					<IconDownTriangle theme="inverse" size="xsmall" decorative />
				}
			>
				{supportLabel}
			</HelpButton>
		</li>
	)
}
/* Support Change */

/*Language Change */

// todo never use `null` refactor to use empty string
export function getLanguageMenuListsValues(url) {
	const params = new URLSearchParams(url)
	if (params.has('mode') && params.get('mode') === 'canada') {
		if (params.has('locale') && params.get('locale') === 'en') {
			params.delete('locale')
			params.append('locale', 'fr')
			const menuItemValue = '?' + params.toString()
			return menuItemValue
		} else if (params.has('locale') && params.get('locale') === 'fr') {
			params.delete('locale')
			params.append('locale', 'en')
			const menuItemValue = '?' + params.toString()
			return menuItemValue
		} else {
			params.append('locale', 'fr')
			const menuItemValue = '?' + params.toString()
			return menuItemValue
		}
	} else {
		return null
	}
}

// todo update messageIds by locale not JSX output
// todo `wca-form.button.us-users-sign-on-french.label` shld be canada not us
export function LanguageMenuList({
	locale,
	intl,
	menuItemValue,
	onChangeLanguageDropDown
}) {
	function onSelectedValueChange(event) {
		onChangeLanguageDropDown(event)
	}

	if (locale === 'en') {
		const label = intl.formatMessage({
			id: 'wca-form.button.canada-users-sign-on-english.label'
		})

		const menuList = (
			<MenuItem value={menuItemValue}>
				<FormattedMessage id="wca-form.button.us-users-sign-on-french.label" />
			</MenuItem>
		)

		return (
			<li className="HeaderLanguage">
				<MenuButton
					kind="simple"
					rightIcon={
						<IconDownTriangle theme="inverse" size="xsmall" decorative />
					}
					label={label}
					onSelectedValueChange={onSelectedValueChange}
				>
					{menuList}
				</MenuButton>
			</li>
		)
	} else {
		const label = intl.formatMessage({
			id: 'wca-form.button.us-users-sign-on-french.label'
		})

		const menuList = (
			<MenuItem value={menuItemValue}>
				<FormattedMessage id="wca-form.button.canada-users-sign-on-english.label" />
			</MenuItem>
		)
		return (
			<li className="HeaderLanguage">
				<MenuButton
					kind="simple"
					rightIcon={
						<IconDownTriangle theme="inverse" size="xsmall" decorative />
					}
					label={label}
					onSelectedValueChange={onSelectedValueChange}
				>
					{menuList}
				</MenuButton>
			</li>
		)
	}
}

// todo rename LanguageDropDown to LanguageDropdown
// todo rename showLanguageDropDown to show
// todo refactor to extract show based on mode to container or reducer
export function LanguageDropDown({
	locale,
	showLanguageDropDown,
	mode,
	intl,
	onChangeLanguageDropDown
}) {
	if (showLanguageDropDown) {
		const url = window.location.search
		const menuItemValue = getLanguageMenuListsValues(url)
		switch (mode) {
			case WFINFOLEASE:
			case PLINFOLEASE:
				return (
					<LanguageMenuList
						locale={locale}
						intl={intl}
						menuItemValue={menuItemValue}
						onChangeLanguageDropDown={onChangeLanguageDropDown}
					/>
				)
			default:
				return null
		}
	} else {
		return null
	}
}
/*Language Change */
