import React, { Fragment, useState, useEffect } from 'react'
import sortBy from 'lodash.sortby'
import { Button, Panel, Form } from '@wf-wfria/ria'
import { FormattedMessage } from 'react-intl'
import { FieldsBuilder } from './FieldsBuilder'
import { SystemMessages } from './SystemMessages'
import { validationRule } from '../../helpers/validation/ocraValidationRule'

export function CreateUserId({
	show,
	locale,
	intl,
	preRegisteredDetails = {},
	fields,
	region,
	messages,
	onClose,
	onCreateUserId,
	mode
}) {
	const { formatMessage } = intl
	const [isFormValid, setFormValidity] = useState(false)
	const [useFields, setFields] = useState(
		getUpdatedFields(fields, region, preRegisteredDetails, mode)
	)
	const defaultValues = getDefaultValues(useFields)
	const [formValues, setFormValues] = useState(defaultValues)
	useEffect(() => {
		const updatedRegisteredDetails = filterNullValues(preRegisteredDetails)
		const setDefaultLanguageValue = setDefaultLanguage(
			updatedRegisteredDetails,
			locale,
			region,
			mode
		)
		setFormValues(defaultValues => ({
			...defaultValues,
			...setDefaultLanguageValue
		}))
		setFields(useFields => {
			return checkHideExtension(preRegisteredDetails, useFields)
		})
	}, [preRegisteredDetails, locale, region, mode])
	function onChange(values) {
		setFields(checkHideExtension(values, useFields))
		setFormValues(values)
	}
	function onSubmit() {
		const {
			message,
			middleInitial,
			contactPreferences,
			langPrefEnabled,
			telephoneExt,
			...values
		} = removeNullValues(formValues)

		const params = {
			...values,
			mInitial: middleInitial,
			phoneType: contactPreferences,
			ext: telephoneExt,
			deliveryType: null,
			telephone: removeMobileFormat(formValues)
		}
		onCreateUserId(params)
	}

	function removeMobileFormat({ telephone = '' }) {
		return telephone.replace(/-/g, '')
	}
	function handleValidation(isFormValid) {
		setFormValidity(isFormValid)
	}
	return (
		<Panel
			className="OCRA_CreateUserId"
			onClose={onClose}
			open={show}
			customStrings={{
				closeButtonAriaLabel: formatMessage({
					id: 'ocra.application.dialog.close'
				})
			}}
			title={formatMessage({
				id: 'ocra.create-user-id.modal.create.user.label'
			})}
			animated
			elementToFocusOnClose="registerButton"
			footer={
				<div>
					<Button
						kind="primary"
						label={formatMessage({
							id: 'ocra.create-user-id.modal.continue.label'
						})}
						disabled={!isFormValid}
						onClick={onSubmit}
					/>
					<Button
						kind="standard"
						label={formatMessage({
							id: 'ocra.create-user-id.modal.cancel.label'
						})}
						onClick={onClose}
					/>
				</div>
			}
		>
			<Fragment>
				<Form className="PaddingTop3" onValidate={handleValidation}>
					<SystemMessages messages={messages} />
					<p className="wf-u-form-required-indicator">
						<FormattedMessage id="ocra.create-user-id.required.field.label" />
					</p>
					<div className="wf-row--padding">
						<div className="wf-row--padding wf-col--desk-6 wf-col--tab-6 wf-col--mob-only-12">
							<FieldsBuilder
								formatMessage={formatMessage}
								fields={sortBy(useFields, function({ order }) {
									return order
								})}
								values={formValues}
								onChange={onChange}
								region={region}
							/>
						</div>
						<div className="wf-col--desk-6 wf-col--tab-6 wf-col--mob-only-12">
							<Instructions />
						</div>
					</div>
				</Form>
			</Fragment>
		</Panel>
	)
}

export function Instructions() {
	return (
		<React.Fragment>
			<h2>
				<strong>
					<FormattedMessage id="create-user-id-form-content.user-id.requirements" />
				</strong>
			</h2>
			<span>
				<strong>
					<FormattedMessage id="create-user-id-form-content.user-id.requirements.must-contain.header-label" />
				</strong>
			</span>
			<ul>
				<li>
					<FormattedMessage id="ocra.create-user-id-form-content.user-id.requirements.must-contain.character-range" />
				</li>
			</ul>
			<span>
				<strong>
					<FormattedMessage id="create-user-id-form-content.user-id.requirements.cannot-contain.header-label" />
				</strong>
			</span>
			<ul>
				<li>
					<FormattedMessage id="create-user-id-form-content.user-id.requirements.cannot-contain.spaces" />
				</li>
				<li>
					<FormattedMessage id="create-user-id-form-content.user-id.requirements.cannot-contain.special-characters" />
				</li>
			</ul>
		</React.Fragment>
	)
}

export function checkHideExtension(values, fields) {
	const useFields = [...fields]
	if (values['contactPreferences']) {
		return useFields.map(useField => {
			const { name } = useField
			if (name === 'telephoneExt') {
				return {
					...useField,
					show: values['contactPreferences'] !== 'cell'
				}
			}
			return useField
		})
	}
	return useFields
}

export function getDefaultValues(fields) {
	const defaultValues = {}
	fields.forEach(({ name, value }) => {
		if (value !== undefined) {
			defaultValues[name] = value
		}
	})
	return defaultValues
}

export function getCountry(country) {
	if (country === 'USA') {
		return [
			{
				value: 'USA',
				label: 'ocra.create-user-id.country.usa.label',
				className: 'wf-col--desk-5 wf-col--tab-12 wf-col--mon-only-12'
			}
		]
	} else if (country === 'CAN')
		return [
			{
				value: 'CAN',
				label: 'ocra.create-user-id.country.can.label',
				className: 'wf-col--desk-5 wf-col--tab-12 wf-col--mon-only-12'
			}
		]
}

export function getLanguageForNA() {
	const searchParams = new URLSearchParams(window.location.href)
	if (searchParams.get('locale') === 'fr_CA') {
		return [
			{
				value: 'CAN',
				label: 'ocra.create-user-id.country.can.label',
				className: 'wf-col--desk-5 wf-col--tab-12 wf-col--mon-only-12'
			}
		]
	}
	return [
		{
			value: 'USA',
			label: 'ocra.create-user-id.country.usa.label',
			className: 'wf-col--desk-5 wf-col--tab-12 wf-col--mon-only-12'
		},
		{
			value: 'CAN',
			label: 'ocra.create-user-id.country.can.label',
			className: 'wf-col--desk-5 wf-col--tab-12 wf-col--mon-only-12'
		}
	]
}
export function getUpdatedFields(fields = [], region, initialValues, mode) {
	let useFields = checkHideExtension(initialValues, fields)
	useFields = useFields.map(item => {
		if (region === 'EMEA' && item.validationType) {
			if (item.name === 'lastName' || item.name === 'firstName') {
				item.validationType.pattern = /^[a-zA-Z,çéâêîôûàèìòùëïüÄäÖöÜüẞß.'\s-]{2,30}$/
			}
			if (item.name === 'middleInitial') {
				item.validationType.pattern = /^[A-Za-zçéâêîôûàèìòùëïüÄäÖöÜüẞß]{1}$/
			}
		}
		return item
	})
	switch (region) {
		case 'NA':
			useFields.push(
				{
					tag: 'TextInput',
					name: 'countryCode',
					order: 8,
					label: 'ocra.create-user-id.country-code.na.label',
					className: 'wf-col--desk-8 wf-col--tab-12 wf-col--mon-only-12',
					required: true,
					validationType: validationRule['countryCode']
				},
				{
					tag: 'Select',
					name: 'language',
					order: 11,
					label: 'ocra.create-user-id.language-preference.label',
					className: 'wf-col--desk-8 wf-col--tab-12 wf-col--mon-only-12',
					required: true,
					choices: [
						{
							label: 'ocra.create-user-id.language.english.us.label',
							value: 'en_US'
						},
						{
							label: 'ocra.create-user-id.language.french.canada.label',
							value: 'fr_CA'
						}
					]
				}
			)
			break
		case 'ANZ':
			useFields.push(
				{
					tag: 'TextInput',
					name: 'countryCode',
					order: 8,
					label: 'ocra.create-user-id.country-code.label',
					className: 'wf-col--desk-5 wf-col--tab-12 wf-col--mon-only-12',
					required: true,
					validationType: validationRule['countryCode']
				},
				{
					tag: 'Select',
					name: 'language',
					order: 11,
					label: 'ocra.create-user-id.language-preference.label',
					className: 'wf-col--desk-8 wf-col--tab-12 wf-col--mon-only-12',
					required: true,
					choices: [
						{
							label: 'ocra.create-user-id.language.english.label',
							value: 'au_AU'
						}
					]
				}
			)
			break
		case 'EMEA':
			useFields.push(
				{
					tag: 'TextInput',
					name: 'countryCode',
					order: 8,
					label: 'ocra.create-user-id.country-code.label',
					className: 'wf-col--desk-5 wf-col--tab-12 wf-col--mon-only-12',
					required: true,
					validationType: validationRule['emeaCountryCode']
				},
				{
					tag: 'Select',
					name: 'language',
					order: 11,
					label: 'ocra.create-user-id.language-preference.label',
					className: 'wf-col--desk-8 wf-col--tab-12 wf-col--mon-only-12',
					required: true,
					choices: [
						{
							label: 'ocra.create-user-id.language.english.uk.label',
							value: 'en_GB'
						},
						{
							label: 'ocra.create-user-id.language.french.france.label',
							value: 'fr_FR'
						},
						{
							label: 'ocra.create-user-id.language.deutsch.germany.label',
							value: 'de_DE'
						}
					]
				}
			)
			break
		default:
			useFields.push(
				{
					tag: 'TextInput',
					name: 'countryCode',
					order: 8,
					label: 'ocra.create-user-id.country-code.label',
					className: 'wf-col--desk-5 wf-col--tab-12 wf-col--mon-only-12',
					required: true,
					validationType: validationRule['countryCode']
				},
				{
					tag: 'Select',
					name: 'language',
					order: 11,
					label: 'ocra.create-user-id.language-preference.label',
					className: 'wf-col--desk-8 wf-col--tab-12 wf-col--mon-only-12',
					required: true,
					choices: [
						{
							label: 'ocra.create-user-id.language.english.label',
							value: 'au_AU'
						},
						{
							label: 'ocra.create-user-id.language.france.label',
							value: 'fr'
						}
					]
				}
			)
			break
	}
	return useFields
}

export function removeNullValues(values) {
	const updatedValues = {}
	Object.keys(values).forEach(key => {
		updatedValues[key] = values[key] || ''
	})
	return updatedValues
}

export function setDefaultLanguage(
	updatedRegisteredDetails,
	locale,
	region,
	mode
) {
	if (['ocraNABCFCAN', 'ocraNAKMFCAN', 'ocraNACISCAN'].includes(mode)) {
		return {
			...updatedRegisteredDetails,
			country: 'CAN',
			language: locale === 'fr' ? 'fr_CA' : 'en_US'
		}
	}
	if (['ocraNAHYGUS', 'ocraNABACUS', 'ocraNACISUS'].includes(mode))
		return { country: 'USA', language: 'en_US', ...updatedRegisteredDetails }
	if (locale === 'de_DE') {
		return { country: 'DEU', language: 'de_DE', ...updatedRegisteredDetails }
	}
	if (locale === 'fr_FR') {
		return { country: 'FRA', language: 'fr_FR', ...updatedRegisteredDetails }
	}
	if (locale === 'fr') {
		return { country: 'CAN', language: 'fr_CA', ...updatedRegisteredDetails }
	}
	if (locale === 'en_GB' || (locale === 'en' && region === 'EMEA')) {
		return { country: 'GBR', language: 'en_GB', ...updatedRegisteredDetails }
	}
	if (locale === 'en_US' || (locale === 'en' && region === 'NA')) {
		return { country: 'USA', language: 'en_US', ...updatedRegisteredDetails }
	}
	if (locale === 'au_AU' || (locale === 'en' && region === 'ANZ')) {
		return { language: 'au_AU', country: 'AUS', ...updatedRegisteredDetails }
	}
	return updatedRegisteredDetails
}

export function filterNullValues(obj) {
	return Object.keys(obj)
		.filter(key => obj[key] !== null)
		.reduce((newObj, key) => {
			newObj[key] = obj[key]
			return newObj
		}, {})
}
